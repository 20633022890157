/*import $ from 'jquery';*/
/*import prestashop from 'prestashop'; */
import supportsPassive from './util/detect-supportsPassive';

export default class Product {
  init() {
    this.createProductSpin();
    this.createInputFile();
    this.coverImage();
    this.createStickyBuy();

    prestashop.on('updatedProduct', (event) => {
      if (event && event.product_minimal_quantity) {
        $('#quantity_wanted').trigger('touchspin.updatesettings', {min: parseInt(event.product_minimal_quantity, 10)});
      }
      $($('.tabs .nav-link.active').attr('href')).addClass('active').removeClass('fade');
      $('.js-product-images-modal').replaceWith(event.product_images_modal);
      this.createInputFile();
      this.coverImage();
    });
  }

  coverImage() {
    $('.js-thumb').on('click mouseover', function () {
      let target = $(this);
      $('.js-product-cover').attr('src', target.data('image-large-src'));
      target.closest('.js-product-images').find('.selected').removeClass('selected');
      target.addClass('selected')
    });
  }

  createInputFile() {
    $('.js-file-input').on('change', function (event) {
      if (this && this.files[0]) {
        $(this).prev().text(this.files[0].name);
      }
    });
  }

  createProductSpin() {
    const quantityInput = $('#quantity_wanted');
    quantityInput.TouchSpin({
      verticalbuttons: true,
      verticalupclass: 'material-icons touchspin-up',
      verticaldownclass: 'material-icons touchspin-down',
      buttondown_class: 'btn btn-touchspin js-touchspin',
      buttonup_class: 'btn btn-touchspin js-touchspin',
      min: parseInt(quantityInput.attr('min'), 10),
      max: 1000000
    });

    $('body').on('change keyup', '#quantity_wanted', (e) => {
      $(e.currentTarget).trigger('touchspin.stopspin');
      prestashop.emit('updateProduct', {
          eventType: 'updatedProductQuantity',
          event: e
      });
    });
  }

  createStickyBuy() {
    let stickyBuy = document.getElementById('sticky-buy');
    if (!stickyBuy) return;
    let buybox = document.getElementById('buybox');
    stickyBuy.querySelector('button.add-to-cart').addEventListener('click', () => {
      buybox.querySelector('button.add-to-cart').click()
    }, false)

    if ('StickyBuy - IntersectionObserver' in window) {
      var observer = new IntersectionObserver(entry => {
        if (entry[0].boundingClientRect.y <= 0) {
          stickyBuy.classList.add("sticky")
        } else {
          stickyBuy.classList.remove("sticky");
        }
      }, {});
      observer.observe(buybox.querySelector('#quantity_wanted'));
    } else {
      let param = supportsPassive() ? { passive: true } : false;
      window.addEventListener('scroll', function () {
        if (window.pageYOffset >= buybox.offsetTop + buybox.offsetHeight) {
          stickyBuy.classList.add("sticky")
        } else {
          stickyBuy.classList.remove("sticky");
        }
      }, param)
    }
  }
  
}
