
import PopperJs from 'popper.js';
PopperJs.Defaults.modifiers.computeStyle.gpuAcceleration = false;

// import 'bootstrap/dist/js/bootstrap.min';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap-touchspin';

import './lib/lory/jquery.lory.js'
import './responsive';
import './customer';
import './listing';
import './product';
import './cart';

import Form from './components/form';
import Browser from './components/browser';
import Checkout from './checkout';
import MainMenu from './components/main-menu';
import Product from './product';
import prestashop from 'prestashop';
import EventEmitter from 'events';
import './components/block-cart';

// "inherit" EventEmitter
for (var i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}


$(document).ready(function () {
  $('select.link').each(function (i, el) {
    $(this).on('change', function (e) {
      window.location = $(this).val();
    });
  });


  const form = new Form();
  const mainmenu = new MainMenu($('.js-top-menu'));
  let product = new Product()
  Browser.init()
  form.init();
  mainmenu.init();
  product.init();
  Checkout.init();

  /* search-widget */ // TODO attr required o esto? 
  /*
  let searchInput = $('.search-widget input[name="s"]')
  $('.search-widget form').submit(function (e) {
    if (searchInput.val().length < 2) {
      e.preventDefault();
      searchInput.focus();
    }
  });
  */

  /*
  // if(supportsTouch()) ...
  $(document).on('mouseenter', '.blockcart.cart-preview', function () {
    $(this).addClass('show')
  });
  $(document).on('mouseleave', '.blockcart.cart-preview', function () {
    $(this).removeClass('show')
  });
  */

  /*$('.blockcart .blockcart-header').popover({
    trigger: 'hover focus',
    selector: '.cart-preview .blockcart-content',
    placement: 'bottom',
  })
  */

  /*
  $('.blockcart .blockcart-header').hoverIntent({
    over: function () {
      console.log('over')
      var dropdown = $(this);//$('.blockcart .dropdown-menu');
      if (!dropdown.hasClass('show')) {
        dropdown.dropdown('toggle');
      }
    },
    out: function () {
      console.log('out')
      var dropdown = $(this);
      if (dropdown.hasClass('show')) {
        dropdown.dropdown('toggle');
      }
    }
  });
  */

/* CMS popup */
$('a.cms-popup').on('click', event => {
  var url = $(event.target).attr('href');
  if (!url) return;

  event.preventDefault();
  let $modal = $(`.js-cms-popup[data-url="${url}"]`);
  if ($modal.length < 1) {
    $modal = $(`<div class="modal fade js-cms-popup" data-url="${url}">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="h1 modal-title js-modal-title"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&#10005;</span>
                </button>
              </div>
              <div class="modal-body js-modal-content"></div>
            </div>
          </div>
        </div>`).appendTo('body');
    
    // TODO: Handle request if no pretty URL
    url += `?content_only=1`;
    $.get(url, content => {
      let $content = $(content);
      $modal.find('.js-modal-title').html($content.find('.page-header h1').contents());
      $modal.find('.js-modal-content').html($content.find('.page-cms').contents());
    }).fail(_ => {
      $modal.one('hidden.bs.modal', _ => $modal.remove());
      $modal.modal('hide')
      //$modal.modal('dispose')
    });
  }
  
  $modal.modal('show');
});

  /**
   * Sliders
   */
  if (prestashop && prestashop.page.page_name === 'index') {
    const carousel = $('.carousel').lory({
      infinite: 1,
      enableMouseEvents: true,
    }).data('lory')

    if (carousel) {
      setInterval(() => {
        carousel.next();
      }, $('.carousel').data('interval'));
    }
  }

  $('.product-slider').lory({
    enableMouseEvents: true,
    slidesToScroll: 3
  });

  $('.product-img-slider').lory({
    infinite: 1,
    enableMouseEvents: true,
  });

  // FIX drag link and img
  $('.js_slider').on('mousedown', '.js_slide', function (event) {
    event.preventDefault();
  });

});


// handle custom file inputs
$('body').on('change', 'input[type="file"].custom-file-input', function (e) {
  const $this = $(this);
  const label = $this.next('.custom-file-label');
  if (!label.length) return
  let name = this && this.files && this.files[0] && this.files[0].name ? this.files[0].name : $this.val();
  label.text(name ? name : $this.data('placeholder'))
});

/*
let testBabel = (v) => {
  const array = [1, 2, 3, 4];
  const [first, second] = array;
  navigator.serviceWorker;
  const arr2 = [7, 6, ...array]
  return arr2;
}

import './testBabel';
*/
