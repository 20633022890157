/*import $ from 'jquery';*/

const ClassName = {
  SHOW       : 'show',
  COLLAPSE   : 'collapse',
  COLLAPSING : 'collapsing',
  COLLAPSED  : 'collapsed'
}

export default class MainMenu {
  constructor(el) {
    this.el = el;
  }

  init() {
    // First level
    this.el
      .on('mouseenter', '> li', this.enter)
      .on('mouseleave', '> li', this.leave)
      .on('click', 'a.nav-link', e => e.stopPropagation())
      .on('click', '.nav-link.d-touch-block.right', this.toggle)
      
    // Categories menu
    this.el.find('.category ul[data-depth="1"]')
      .on('mouseenter', '> li', this.enter)
      .on('mouseleave', '> li', this.leave)
      .on('click', 'a.nav-link', e => e.stopPropagation())
      .on('click', '.nav-link.d-touch-block.right', this.toggle)
  }

  toggle(e) {
    if (prestashop.responsive.mobile) return;
    e.stopPropagation();

    const $this = $(this)
    $this.parent().find('.collapse.show').collapse('hide');
    $this.find('> .collapse').collapse('show');
  }

  enter(e) {
    if (prestashop.responsive.mobile) return;
    this.children[0].classList.remove(ClassName.COLLAPSED)
    this.children[0].setAttribute('aria-expanded', true)
    if (this.children[1]) {
      this.children[1].classList.add(ClassName.SHOW)
    }
    
    //$(this.children[0]).removeClass(ClassName.COLLAPSED).attr('aria-expanded', true)
    //$(this.children[1]).addClass(ClassName.SHOW)
  }

  leave(e) {
    if (prestashop.responsive.mobile) return;
    this.children[0].classList.add(ClassName.COLLAPSED)
    this.children[0].setAttribute('aria-expanded', false)
    if (this.children[1]) {
      this.children[1].classList.remove(ClassName.SHOW)
    }

    //$(this.children[0]).addClass(ClassName.COLLAPSED).attr('aria-expanded', false)
    //$(this.children[1]).removeClass(ClassName.SHOW)
  }

}
