/*import $ from 'jquery';
import prestashop from 'prestashop';
*/

// TODO https://jsfiddle.net/2Lz6gz3b/9/

prestashop.responsive = prestashop.responsive || {};

prestashop.responsive.current_width = window.innerWidth;
prestashop.responsive.min_width = 768;
prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;

function swapChildren(obj1, obj2)
{
	var temp = obj2.children().detach();
	obj2.empty().append(obj1.children().detach());
	obj1.append(temp);
}

function toggleMobileStyles()
{
	if (prestashop.responsive.mobile) {
		$("*[id^='_desktop_']").each(function(idx, el) {
			var target = $('#' + el.id.replace('_desktop_', '_mobile_'));
			if (target.length) {
				swapChildren($(el), target);
			}
		});
		$('[data-collapse-hide-mobile]').collapse('hide'); // TODO test

		$('.mainmenu .collapse').collapse('hide');
	} else {
		$("*[id^='_mobile_']").each(function(idx, el) {
			var target = $('#' + el.id.replace('_mobile_', '_desktop_'));
			if (target.length) {
				swapChildren($(el), target);
			}
		});
		$('[data-collapse-hide-mobile]').not('.show').collapse('show'); // TODO test
        $('[data-modal-hide-mobile].show').modal('hide');
	}
	prestashop.emit('responsive update', {
		mobile: prestashop.responsive.mobile
	});
}


/**
 * Detect touch screen
 */
function supportsTouch() {
	return 'ontouchstart' in window || window.navigator.msPointerEnabled || 'ontouchstart' in document.documentElement;

	/*
	var supportsTouch = false;

    if ('ontouchstart' in window) // iOS & android
        supportsTouch = true;
    else if(window.navigator.msPointerEnabled) // Win8
        supportsTouch = true;
    else if ('ontouchstart' in document.documentElement) // Controversal way to check touch support
		supportsTouch = true;

	return supportsTouch;
	*/
}

/**
 * Detecta si el usuario usa el dedo (Touch) o un puntero (mouse...) y cambia la clase "touch" del body.
 * TODO Eventos touch disparan mouse events.
 * TODO passive if supported
 */
/*
function detectInputDevice() {

	if (supportsTouch()) {
		document.body.classList.add('touch');
		detectMouse()
	} else {
		document.body.classList.add('mouse');
		detectTouch()
	}

	function detectTouch() {
		window.addEventListener('touchstart', function onFirstTouch() {
			document.body.classList.add('touch');
			document.body.classList.remove('mouse');
			window.removeEventListener('touchstart', onFirstTouch, false);
			detectMouse()
		}, false);
	}

	function detectMouse() {
		window.addEventListener('mousemove', function onFirstHover() {
			document.body.classList.add('mouse');
			document.body.classList.remove('touch');
			window.removeEventListener('mousemove', onFirstHover, false);
			detectTouch()
		}, false);
	}
}
*/
/**
 * Detecta si el usuario usa el dedo (Touch)
 */
function detectTouching() {
	document.body.classList.add('mouse');
	if (!supportsTouch()) {
		return;
	}

	//const touchEventParams = supportsPassive() ? { passive: true } : false;
	const touchEventParams = typeof window.document.documentMode === 'undefined' ? { passive: true } : false;

	window.addEventListener('touchstart', function onFirstTouch() {
		document.body.classList.add('touch');
		document.body.classList.remove('mouse');
		window.removeEventListener('touchstart', onFirstTouch, false);
	}, touchEventParams);
}


$(window).on('resize', function() {
	var _cw = prestashop.responsive.current_width;
	var _mw = prestashop.responsive.min_width;
	var _w = window.innerWidth;
	var _toggle = (_cw >= _mw && _w < _mw) || (_cw < _mw && _w >= _mw);
	prestashop.responsive.current_width = _w;
  	prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
	if (_toggle) {
		toggleMobileStyles();
	}
});

$(document).ready(function() {
	if (prestashop.responsive.mobile) {
		toggleMobileStyles();
		document.body.classList.add('touch');
	} else {
		detectTouching();
	}
	//document.body.classList.add(supportsTouch() ? 'touch' : 'no-touch');
	//detectInputDevice()
	
});
