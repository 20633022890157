/*import $ from 'jquery';*/

export default class Form {
  init(){
    this.parentFocus();
    this.togglePasswordVisibility();
  }

  parentFocus() {
    $('.js-child-focus').focus(function () {
      $(this).closest('.js-parent-focus').addClass('focus');
    });
    $('.js-child-focus').focusout(function () {
      $(this).closest('.js-parent-focus').removeClass('focus');
    });
  }

  // Comprobar si se usa data-state="show" ...
  togglePasswordVisibility() {
    $('button[data-action="show-password"]').on('click', function () {
      let $this = $(this);
      let elm = $this.closest('.input-group, .input-group-inside').children('input.js-visible-password');
      if (elm.attr('type') === 'password') {
        elm.attr('type', 'text');
        $this.find('[data-state="show"]').attr('hidden', true);
        $this.find('[data-state="hide"]').removeAttr('hidden');
      } else {
        elm.attr('type', 'password');
        $this.find('[data-state="show"]').removeAttr('hidden');
        $this.find('[data-state="hide"]').attr('hidden', true);
      }
    });
  }
}
