/*import $ from 'jquery';
import prestashop from 'prestashop';*/

$(document).ready(() => {
  prestashop.on('clickQuickView', function (elm) {
    let data = {
      'action': 'quickview',
      'id_product': elm.dataset.idProduct,
      'id_product_attribute': elm.dataset.idProductAttribute,
    };
    $.post(prestashop.urls.pages.product, data, null, 'json').then(function (resp) {
      $('body').append(resp.quickview_html);
      let productModal = $(`#quickview-modal-${resp.product.id}-${resp.product.id_product_attribute}`);
      productModal.modal('show');
      productConfig(productModal);
      productModal.on('hidden.bs.modal', function () {
        productModal.remove();
      });
    }).fail((resp) => {
      prestashop.emit('handleError', { eventType: 'clickQuickView', resp: resp });
    });
  });

  var productConfig = (qv) => {

    $('.js-thumb').on('click mouseover', function () {
      let target = $(this)
      target.closest('.images-container').find('.js-product-cover').attr('src', target.data('image-large-src'));
      target.closest('.js-product-images').find('.selected').removeClass('selected');
      target.addClass('selected')
    });

    qv.find('#quantity_wanted').TouchSpin({
      verticalbuttons: true,
      verticalupclass: 'material-icons touchspin-up',
      verticaldownclass: 'material-icons touchspin-down',
      buttondown_class: 'btn btn-touchspin js-touchspin',
      buttonup_class: 'btn btn-touchspin js-touchspin',
      min: 1,
      max: 1000000
    });
  };

  const parseSearchUrl = function (event) {
    if (event.target.dataset.searchUrl !== undefined) {
      return event.target.dataset.searchUrl;
    }

    if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL');
    }

    return $(event.target).parent()[0].dataset.searchUrl;
  };

  $('body').on('change', '#search_filters input[data-search-url]', function (event) {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-filters-clear-all', function (event) {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('click', '.js-search-link', function (event) {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).closest('a').get(0).href);
  });

  /* ver si se utiliza, idea para noscript filter form */
  /*$('body').on('change', '#search_filters select', function (event) {
    const form = $(event.target).closest('form');
    prestashop.emit('updateFacets', '?' + form.serialize());
  });*/

  prestashop.on('updateProductList', (data) => {
    $('#search_filters').replaceWith(data.rendered_facets);
    $('#js-active-search-filters').replaceWith(data.rendered_active_filters);
    $('#js-product-list-top').replaceWith(data.rendered_products_top);
    $('#js-product-list').replaceWith(data.rendered_products);
    $('#js-product-list-bottom').replaceWith(data.rendered_products_bottom);
    if (data.rendered_products_header) {
        $('#js-product-list-header').replaceWith(data.rendered_products_header);
    }
    window.scrollTo(0, 0);
  });
});
