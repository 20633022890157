/*import $ from 'jquery';
import prestashop from 'prestashop';*/

export default class Checkout {
  static init() {
    if ($('body#checkout').length === 1) {
      Checkout.setUpCheckout();
    }

    prestashop.on('updatedDeliveryForm', (params) => {
      if (typeof params.deliveryOption !== 'undefined' && params.deliveryOption.length > 0) {
        // Hide all carrier extra content ...
        $(".carrier-extra-content").slideUp();
        // and show the one related to the selected carrier
        params.deliveryOption.next(".carrier-extra-content").slideDown();
      }
    });
  }

  static setUpCheckout() {
    
    // Crear cuenta e invitado usan mismo formulario.
    function showHideGuest() {
      if ($('.js-customer-id .active').hasClass('js-guest-order')) {
        $('.js-customer-form .js-guest-tip').show(500);
        $('.js-customer-form input[type="password"]').removeAttr('required').closest('.row').find('.form-control-comment').fadeIn(500);
      } else {
        $('.js-customer-form .js-guest-tip').hide(500);
        $('.js-customer-form input[type="password"]').attr('required', '').closest('.row').find('.form-control-comment').fadeOut(500);
      }
    }

    showHideGuest()

    $('.js-customer-id a[data-toggle="tab"]').on('shown.bs.tab', showHideGuest)

    $('.js-terms a').on('click', (event) => {
      event.preventDefault();
      var url = $(event.target).attr('href');
      if (url) {
        // TODO: Handle request if no pretty URL
        url += `?content_only=1`;
        $.get(url, (content) => {
          $('#modal').find('.js-modal-content').html($(content).find('.page-cms').contents());
        }).fail((resp) => {
          prestashop.emit('handleError', {eventType: 'clickTerms', resp: resp});
        });
      }

      $('#modal').modal('show');
    });

    $('.js-gift-checkbox').on('click', (event) => {
      $('#gift').collapse('toggle');
    });
  }

}
