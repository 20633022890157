/*import $ from 'jquery';*/

export default class Browser {
  static init() {
    if (this.isMSIE()) {
      $('.header-banner')
        .addClass('old-browser-alert')
        .html('We recommend upgrading to the latest <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>, <a href="https://chrome.google.com">Google Chrome</a>, or <a href="https://mozilla.org/firefox/">Firefox</a>.')
    }
  }

  static isMSIE () {
    return typeof window.document.documentMode !== 'undefined';
    // return navigator.userAgent.indexOf("MSIE ") >= 0 || navigator.userAgent.indexOf("Trident") >= 0;
  }
}
